import {createSlice} from "@reduxjs/toolkit";

const flightCreateSlice = createSlice({
  name: "flightCreateState",
  initialState: {
    geotiff: {
      loading: false
    },
    rawFlight: {
      loading: false
    }
  },
  reducers: {
    createGeoTiffFlightAction(state, action) {
      return {
        ...state,
        geotiff: {...state.geotiff, loading: true}
      }
    },
    createRawFlightAction(state, action) {
      return {
        ...state,
        rawFlight: {...state.rawFlight, loading: true}
      }
    },
    setRawFlightLoadingAction(state, action) {
      return {
        ...state,
        rawFlight: {...state.rawFlight, loading: false}
      }
    },
    setGeoTiffLoadingAction(state, action) {
      return {
        ...state,
        geotiff: {loading: false}
      }
    },
    rawFilesDropAction(state, action)  {
      return {
        ...state,
        rawFlight: {...state.rawFlight, loading: true}
      }
    },
    rawFilesDropActionComplete(state, action)  {
      return {
        ...state,
        rawFlight: {...state.rawFlight, loading: false}
      }
    },
    setRawFileDataAction(state, action) {
      const prevDataArray = !!state.rawFlight[action.payload.flightUuid].data ? state.rawFlight[action.payload.flightUuid].data : []
      return {
        ...state,
        rawFlight: {
          ...state.rawFlight,
          [action.payload.flightUuid]: {
            ...state.rawFlight[action.payload.flightUuid],
            data: [...prevDataArray, action.payload.data]
          }
        }
      }
    },
    updateRawFlightItemUploadStatusAction(state, action) {
      void (state.rawFlight[action.payload.flightUuid].data.forEach((file, index) => {
        if (file.capturedTime === action.payload.capturedTime) {
          state.rawFlight[action.payload.flightUuid].data[index].uploaded = true
        }
      }))
    },
    updateRawFlightDataImageURLAction(state, action) {
      void (state.rawFlight[action.payload.flightUuid].data.forEach((file, index) => {
        if (file.capturedTime === action.payload.capturedTime) {
          state.rawFlight[action.payload.flightUuid].data[index].image = action.payload.image
        }
      }))
    },
    removeRawFlightItemAction(state, action) {
      void (delete state.rawFlight[action.payload.flightUuid])
    },
    setTotalRawImageAction(state, action) {
      return {
        ...state,
        rawFlight: {
          ...state.rawFlight,
          [action.payload.flightUuid]: {
            ...state.rawFlight[action.payload.flightUuid],
            totalImages: action.payload.totalImages,
            uploading: action.payload.uploading
          }
        }
      }
    },
    setRawFlightUploadingAction(state, action) {
      return {
        ...state,
        rawFlight: {
          ...state.rawFlight,
          [action.payload.flightUuid]: {...state.rawFlight[action.payload.flightUuid], uploading: action.payload.status}
        }
      }
    },
    increaseRawImageForUploadProcessAction(state, action) {
      return {
        ...state,
        rawFlight: {
          ...state.rawFlight,
          [action.payload.flightUuid]: {
            ...state.rawFlight[action.payload.flightUuid],
            uploadedImages: state.rawFlight[action.payload.flightUuid].uploadedImages + 1
          }
        }
      }
    },
    decreaseRawImageForExifProcessAction(state, action) {
      return {
        ...state,
        rawFlight: {
          ...state.rawFlight,
          [action.payload.flightUuid]: {
            ...state.rawFlight[action.payload.flightUuid],
            totalImages: state.rawFlight[action.payload.flightUuid].totalImages - 1
          }
        }
      }
    },
    downloadSampleGCPFileAction() {
    },
    gcpFileDropAction() {
    },
    checkRawFlightToRemoveAction(){}
  }
})

export const {
  createGeoTiffFlightAction,
  createRawFlightAction,
  rawFilesDropAction,
  setRawFileDataAction,
  setTotalRawImageAction,
  decreaseRawImageForExifProcessAction,
  downloadSampleGCPFileAction,
  gcpFileDropAction,
  updateRawFlightDataImageURLAction,
  removeRawFlightItemAction,
  increaseRawImageForUploadProcessAction,
  setRawFlightUploadingAction,
  updateRawFlightItemUploadStatusAction,
  setRawFlightLoadingAction,
  setGeoTiffLoadingAction,
  checkRawFlightToRemoveAction,
  rawFilesDropActionComplete
} = flightCreateSlice.actions

export default flightCreateSlice.reducer