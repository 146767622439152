import { useForm } from "../../../hooks/useForm";
import Grid from "@mui/material/Grid";
import TextField from "../../Common/TextField";
import Box from "@mui/material/Box";
import DatePicker from "../../Common/DatePicker";
import FileDropBox from "../../Common/FileDropBox";
import { useDispatch, useSelector } from "react-redux";
import { createGeoTiffFlightAction } from "../../../redux/slices/flight/create";
import { useSnackbar } from "notistack";
import SubmitResetButton from "../../Common/SubmitResetButton";
import { useHistory } from "react-router-dom";

const initialValues = {
  flight_name: "",
  flown_date: new Date(),
  ortho: null,
  elevation: null,
};

const GeoTiff = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const loading = useSelector((state) => state.flightCreate.geotiff.loading);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("flight_name" in fieldValues)
      temp.flight_name = fieldValues.flight_name
        ? ""
        : "This field is required.";
    if ("flown_date" in fieldValues)
      temp.flown_date = fieldValues.flown_date ? "" : "This field is required.";
    if ("ortho" in fieldValues)
      temp.ortho = fieldValues.ortho ? "" : "This field is required.";
    if ("elevation" in fieldValues)
      temp.elevation = fieldValues.elevation ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm, setValues } =
    useForm(initialValues, validate, true);

  const handleOrthoFile = (files) => {
    setValues((prevState) => ({
      ...prevState,
      ortho: files[0],
      history: history,
    }));
  };

  const handleElevationFile = (files) => {
    setValues((prevState) => ({
      ...prevState,
      elevation: files[0],
    }));
  };

  const handleSubmit = () => {
    if (validate()) {
      enqueueSnackbar("uploading", { persist: true, preventDuplicate: true });
      dispatch(createGeoTiffFlightAction({ ...values }));
    }
  };

  return (
    <Box pt={2} m={1}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <TextField
            label="Flight Name"
            fullWidth
            required
            name="flight_name"
            value={values.flight_name}
            error={errors.flight_name}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item>
          <DatePicker
            label="Flight Date"
            onChange={handleInputChange}
            value={values.flown_date}
            required
            fullWidth
            name="flown_date"
            error={errors.flown_date}
          />
        </Grid>
        <Grid item>
          <FileDropBox
            label="Orthomosaic File"
            acceptFileType="image/tiff"
            onFileDrop={handleOrthoFile}
            name="ortho"
            error={errors.ortho}
          />
        </Grid>
        <Grid item>
          <FileDropBox
            name="elevation"
            label="Elevation File"
            acceptFileType="image/tiff"
            onFileDrop={handleElevationFile}
            error={errors.elevation}
          />
        </Grid>
        <Grid item>
          <SubmitResetButton
            handleSubmit={handleSubmit}
            resetForm={resetForm}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeoTiff;
