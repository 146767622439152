import Box from "@mui/material/Box";
import {useDropzone} from "react-dropzone";
import Paper from "@mui/material/Paper";
import {styled} from "@mui/material";
import Typography from "@mui/material/Typography";
import {getIconComponentByType} from "../../utils/assets";
import {useEffect, useState} from "react";

const PaperStyled = styled(Paper)(({theme}) => ({
  width: "100%",
  height: 184,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
}))

const FileDropBox = ({
                       name,
                       label,
                       acceptFileType,
                       onFileDrop,
                       multiple = false,
                       error = "",
                       value = null,
                       is_file_accepted = true,
                       ...props
                     }) => {

  const [errors, setErrors] = useState("");
  const [acceptMessage, setAcceptMessage] = useState("")

  const handleFileReject = (files) => {
    setErrors(files[0].errors[0].message)
    setAcceptMessage("")
  }

  useEffect(() => {
    setErrors(error)
    if(!value) {
      setAcceptMessage("")
    }
  }, [error, value]);


  const handleFileAccept = (files) => {
    setErrors("")
    onFileDrop(files)
    if (is_file_accepted) {
      if (files.length > 1) {
        setAcceptMessage(`${files.length} files selected`)
      } else {
        setAcceptMessage(files[0].name)
      }
    }
  }

  const {
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: acceptFileType,
    onDropAccepted: handleFileAccept,
    onDropRejected: handleFileReject,
    multiple: multiple,
    ...props
  });

  const {ref, ...rootProps} = getRootProps()


  return (
    <Box ref={ref}>
      <PaperStyled {...rootProps} variant="outlined">
        <input {...getInputProps()} name={name}/>
        {getIconComponentByType("cloudUpload")}
        <Typography variant="h6">Drag & Drop </Typography>
        <Typography variant="h6">{label}</Typography>
        <Typography>Format Details</Typography>
        {errors && <Typography style={{
          color: "red",
          padding: 5,
          margin: 0,
          fontSize: 14
        }}>{errors}</Typography>}
        {acceptMessage &&
        <Typography noWrap style={{color: "green", padding: 5, margin: 0, fontSize: 14}}>{acceptMessage.length > 20 ? `${acceptMessage.slice(0, 20)}...`: acceptMessage}</Typography>}
      </PaperStyled>
    </Box>
  )
}

export default FileDropBox