import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { selectFlightAction } from "../../redux/slices/flight/selected";
import IconButton from "@mui/material/IconButton";
import {
  getIconComponentByType,
  getIconComponentByTypeWithProps,
} from "../../utils/assets";
import { formatDate, getStringDateFromDate } from "../../utils/formattings";
import DatePicker from "../Common/DatePicker";
import Badge from "@mui/material/Badge";
import Button from "../Common/Button";
import Stack from "@mui/material/Stack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import { PickersDay } from "@mui/lab";
import { styled } from "@mui/material";
import { measurementDeleteAction } from "redux/slices/measurement/delete";
import { resetCreateMeasureAction } from "redux/slices/measurement/create";
import {
  getMeasurementListAction,
  updateMeasureDataListAction,
  resetMeasurementFiltersAction
} from "redux/slices/measurement/list";

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  "&:disabled": {
    color: "#808080",
  },
}));

const FlightSelector = () => {
  const selectedFlight = useSelector((state) => state.flightSelected);

  const flights = useSelector((state) => {
    return state.flightList.data.map(
      (item, idx) =>
        state.flightList.data[state.flightList.data.length - 1 - idx]
    );
  });
  const flownOnDates = flights.map((flight) =>
    getStringDateFromDate(new Date(flight.flown_date))
  );
  const [open, setOpen] = useState(false);
  const [previousFlights, setPreviousFlights] = useState([]);
  const [nextFlights, setNextFlights] = useState([]);

  const dispatch = useDispatch();
  const handleDateChange = (e) => {
    const currentSelectedFlight = flights.filter(
      (flight) =>
        getStringDateFromDate(e.target.value) ===
        getStringDateFromDate(new Date(flight.flown_date))
    );
    dispatch(selectFlightAction({ flight: currentSelectedFlight[0] }));
    setOpen(false);
  };

  useEffect(() => {
    const currentElementIndex = flownOnDates.findIndex(
      (date) =>
        date === getStringDateFromDate(new Date(selectedFlight.flown_date))
    );
    setPreviousFlights(flights.slice(0, currentElementIndex));
    setNextFlights(
      flights.slice(
        currentElementIndex + 1,
        currentElementIndex + flights.length
      )
    );
    dispatch(getMeasurementListAction());
  }, [selectedFlight]);

  const updateMeasurements = () => {
    dispatch(resetCreateMeasureAction())
    dispatch(getMeasurementListAction());
}

  const handleNext = (e) => {
    e.preventDefault();
    dispatch(selectFlightAction({ flight: nextFlights[0] }));
    updateMeasurements();
  };

  const handlePrev = (e) => {
    e.preventDefault();
    dispatch(
      selectFlightAction({
        flight: previousFlights[previousFlights.length - 1],
      })
    );
    updateMeasurements();
  };

  return (
    Object.keys(selectedFlight).length !== 0 && (
      <Stack direction="row">
        <IconButtonStyled
          color="primary"
          disabled={previousFlights.length === 0}
          onClick={handlePrev}
        >
          {getIconComponentByType("arrowBackIos")}
        </IconButtonStyled>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            TextFieldComponent={() => null}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <Box
                sx={{ display: "flex", alignItems: "center" }}
                ref={inputRef}
                {...inputProps}
              >
                <Button
                  color="inherit"
                  variant="outlined"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(true);
                  }}
                  label={formatDate(selectedFlight.flown_date, "yyyy-mm-dd")}
                />
              </Box>
            )}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={new Date(selectedFlight.flown_date)}
            onChange={handleDateChange}
            autoOk
            disableFuture
            // variant="inline"
            shouldDisableDate={(day) => {
              return !flownOnDates.includes(getStringDateFromDate(day));
            }}
            renderDay={(date, selectedDate, pickersDayProps) => {
              const isSelected =
                date &&
                !!flownOnDates.find(
                  (flownDate) => flownDate === getStringDateFromDate(date)
                );
              // You can also use our internal <Day /> component
              return (
                <Box display="flex-inline">
                  <Badge
                    badgeContent={
                      isSelected
                        ? getIconComponentByTypeWithProps("flightIcon")({
                            color: "primary",
                          })
                        : null
                    }
                  >
                    <PickersDay {...pickersDayProps} />
                  </Badge>
                </Box>
              );
              // return <Badge
              //   badgeContent={isSelected ? getIconComponentByTypeWithProps("flightIcon")({color: "primary"}) : undefined}>{dayComponent}</Badge>;
            }}
          />
        </LocalizationProvider>
        <IconButtonStyled
          color="primary"
          disabled= {nextFlights.length === 0}
          onClick={handleNext}
        >
          {getIconComponentByType("arrowForwardIos")}
        </IconButtonStyled>
      </Stack>
    )
  );
};

export default FlightSelector;
