import _ from "lodash";
import {addMeasurementClickEvent} from "../../redux/sagas/helpers/measurementHelper";
const geolib = require('geolib');

export function getMapBoxResourceId(name, flightId, isLayer = false) {
  const type = isLayer ? "layer" : "source";
  return `${type}-${name}-${flightId}`.toLowerCase();
}


export function createRasterLayer(map, rasterLayer, sourceId, layerId, tileSize = 256, cogUrl) {
  if(cogUrl) {
    const url =  `https://25p5jflglc.execute-api.ca-central-1.amazonaws.com/cog/tiles/{z}/{x}/{y}/WebMercatorQuad&url=${cogUrl}&bidx=1&bidx=2&bidx=4`;
    map.addSource(sourceId, {
      type: 'raster',
       tiles: [
        url
      ],

      tileSize: 256,
    });

    // map.addSource(sourceId, {
    //   'type': 'raster',
    //   "tiles": [`https://25p5jflglc.execute-api.ca-central-1.amazonaws.com/cog/tiles/{z}/{x}/{y}/?TileMatrixSetId=WebMercatorQuad&scale=1&url=${cogUrl}`],
    //   'tileSize': tileSize
    // })
  }
else {
  map.addSource(sourceId, {
    'type': 'raster',
    "url": `mapbox://${rasterLayer['mapboxTileIdKey']}/?fresh=true`,
    'tileSize': tileSize
  })
}

// map.addLayer({
//   id: 'custom-tiles',
//   type: 'raster',
//   source: sourceId,
// });
  map.addLayer({
    "id": layerId,
    "type": "raster",
    "source": sourceId,
    "layout": rasterLayer.layout,
    "paint": rasterLayer.paint,
    "minzoom": 0,
    "maxzoom": 22
  });
}

export function setRasterLayerToMap(map, rasterLayerInfo, flightId, visibility, cogUrl) {
  const sourceId = getMapBoxResourceId(rasterLayerInfo.name, flightId, false)
  const layerId = getMapBoxResourceId(rasterLayerInfo.name, flightId, true)
  const layer = map.getLayer(layerId)
  if (layer) {
    map.setLayoutProperty(layerId, "visibility", visibility)
  } else {
    createRasterLayer(map, rasterLayerInfo, sourceId, layerId, 256, cogUrl)
  }

}

function createGeoJsonLayer(map, geoJsonLayerInfo, sourceId, layerId, subLayerKey) {
  const subLayer = geoJsonLayerInfo.subLayers[subLayerKey];
  if (!map.getSource(sourceId)) {
    map.addSource(sourceId, {
      type: 'geojson',
      data: subLayer.data,
    });
  } else {
    map.getSource(sourceId).setData(subLayer.data);
  }

  if (!map.getLayer(layerId)) {
    map.addLayer({
      'id': layerId,
      'source': sourceId,
      'type': subLayer.renderType,
      'paint': subLayer.paint,
      'layout': geoJsonLayerInfo.layout,
  });
  const style = map.getStyle();
  const lastLayerId = style.layers[style.layers.length - 1].id;
  map.moveLayer(layerId, lastLayerId);

  }
}

export function setGeoJsonLayerToMap(map, geoJsonLayerInfo, flightId, visibility) {
  Object.keys(geoJsonLayerInfo.subLayers).forEach(sublayerKey => {
    const sourceId = getMapBoxResourceId(`${geoJsonLayerInfo.name}-${sublayerKey}`, flightId, false)
    const layerId = getMapBoxResourceId(`${geoJsonLayerInfo.name}-${sublayerKey}`, flightId, true)
    let layer = map.getLayer(layerId)
    if (_.isEmpty(layer)) {
      createGeoJsonLayer(map, geoJsonLayerInfo, sourceId, layerId, sublayerKey)
    } else {
      const data = geoJsonLayerInfo.subLayers[sublayerKey].data
      const source = map.getSource(sourceId)
      source.setData(data)
    }
    map.setLayoutProperty(layerId, "visibility", visibility)
    if (geoJsonLayerInfo.name === "user-measurement") addMeasurementClickEvent(layerId, map)
  })
}

export function setGeoJsonData(map, layerId, flightId, data) {
  const sourceId = layerId.replace("layer", "source")
   const source = map.getSource(sourceId)
  if(source) {
    source.setData(data)
  }
}

export function updateSourceLayerGeoJsonData(map, sourceId, data){
  const source = map.getSource(sourceId)
  if (source){
    source.setData(data)
  }
}

export function setMapLayerLayoutProperty(layerId, property, value) {
   if (!window.map) return
  const layer = window.map.getLayer(layerId)
  if (layer) {
    window.map.setLayoutProperty(layerId, property, value)
    return true
  }
   return false
}

export function removeMapLayer(layerId) {
  if (!window.map) return
  const layer = window.map.getLayer(layerId)
  if (layer) window.map.removeLayer(layerId).removeSource(layerId.replace("layer", "source"))
}

export function getSelectedFlightLayers(layers, selectedFlightId) {
  return Object.entries(layers).filter(layer => layer[0].includes(selectedFlightId))
}


export function getMapMapLayers() {
  if (!window.map) return
    const style = window.map.getStyle();
    const layers = style.layers;
    const layers2 = window.map.getLayers()
}

export const formatDate = (date) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const day = date.getDate().toString().padStart(2, '0'); 
  const month = months[date.getMonth()]; 
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

export const ticketTypes = [
  { data_upload: 'Data Upload ( any issues related to project upload)' },
  { data_export: 'Data Export (any problem regarding to data export)' } ,
  { measurement: 'Measurement (any problem related to measurements)' },
  { data_visibility: 'Data Visibility Problem (ortho, cut fill, design cut fill and hillshade)' },
  { other: 'Other (anything else)'}
]


export const checkCutandFill = (value, unit, label) => {
  if (!value) return false;

  const unitString = unit && checkUnit(label) !== '0' ? unit + formatSuperscript(checkUnit(label)) : unit;
  // const unitString = unit ? unit + formatSuperscript(checkUnit(label)) : '';


  if(label === 'design_elevation') {
   return value.toFixed(2) + ' ' + unitString;
  }
  
  if (value > 0 && label !== 'design_elevation') {
    return value.toFixed(2) + ' ' + unitString + ' (Fill)';
  } else {
    return value.toFixed(2) + ' ' + unitString + ' (Cut)';
  }
}

const formatSuperscript = (num) => {
  const superscriptDigits = { '2': '²', '3': '³' };
  const res = num.toString().split('').map(digit => superscriptDigits[digit] || digit).join('');
  if(res) return res;
   else
    return '';
};

export const measurement_units = {
  0: ['X', 'Y', 'elevation', 'progress_cut_fill', 'design_cut_fill', 'perimeter', 'length', 'design_elevation',
             'diff_in_elevation'],
  2: ['area'],
  3: ['progress_volume', 'stockpile_volume_TIN_METHOD','stockpile_volume_MIN_METHOD', 'design_volume'],
}

export const checkUnit = (value) => {
  for (const key in measurement_units) {
    if (measurement_units[key].includes(value)) {
      return key === 0 ? 'm' : key;
    }
  }
  return '';
}

export const cut = (text: string, maxSize: number = 22, isTreeDots: boolean = true) => {
  if(text && text.length > maxSize) {
      const newText= text.slice(0,maxSize-3);
      return isTreeDots ? `${newText}...` : newText;
  }
  return text;
};


export const getCenterOfLocations = (locations) => {
  const latitudes = locations.map(location => location[1]);
  const longitudes = locations.map(location => location[0]);
  const center = geolib.getCenterOfBounds(locations);

  return {
      latitude: center.latitude,
      longitude: center.longitude
  };
}