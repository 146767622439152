import {createSlice} from "@reduxjs/toolkit";

const flightListSlice = createSlice({
  name: "flightListState",
  initialState: {
    loading: false,
    data: []
  },
  reducers: {
    getFlightListAction(state, action){
      return {
        ...state,
        loading: true
      }
    },
    setFlightListDataAction(state, action){
      return {
        ...state,
        loading: false,
        data: [...action.payload.data]
      }

    },
    createFlightWebSocketAction(){},
    updateFlightDataListAction(state, action){
      void (state.data = [...state.data.filter(item => item.id !== action.payload.data.id), action.payload.data])
    }
  }
})

export const {getFlightListAction, setFlightListDataAction, createFlightWebSocketAction, updateFlightDataListAction} = flightListSlice.actions

export default flightListSlice.reducer