import {useDispatch, useSelector} from "react-redux";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Grid from "@mui/material/Grid";
import SeeItem from "./SeeItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {rearrangeRasterLayersAction} from "../../redux/slices/layers/rasterLayersSlice";

const SeeList = () => {

  const dispatch = useDispatch()
  const selectFlightId = useSelector(state => state.flightSelected.id)

  const rasterLayers = useSelector(state => {
    let unsortedLayers = {}
    Object.keys(state.rasterLayers).forEach(layerKey => {
      if (state.rasterLayers[layerKey].showInLayerSwitcher === true && layerKey.includes(selectFlightId)) unsortedLayers[layerKey] = state.rasterLayers[layerKey]
    })
    let layersArray = []
    for (const layer in unsortedLayers) {
      layersArray.push({name: layer, data: unsortedLayers[layer]})
    }
    layersArray = layersArray.sort((a, b) => b.data.order - a.data.order)
    return layersArray
  })

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const destinationLayer = rasterLayers[result.destination.index].name
    const sourceLayer = rasterLayers[result.source.index].name
    dispatch(rearrangeRasterLayersAction({sourceLayer, destinationLayer}))
  }

  return (
    <>
      <Box alignItems="center" display="flex" flexDirection="column" pt={2} pb={2}>
        <Typography variant="h4">See</Typography>
      </Box>
      <Divider/>
       <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="rasterLayers">
        {(provided) => (
          <Grid container {...provided.droppableProps} ref={provided.innerRef} spacing={1} sx={{
          padding: "8px 8px 8px"
        }}>
            {rasterLayers.length > 0 && window.map ?
              rasterLayers.map((rasterLayer, index) => (
                <Draggable key={rasterLayer.name} draggableId={rasterLayer.name} index={index}>
               {(provided) => (
                <Grid item container {...provided.draggableProps} ref={provided.innerRef}>
                  <Grid item xs>
                    <SeeItem
                        layerData={rasterLayer.data}
                        layerKey={rasterLayer.name}
                        provided={provided}
                      />
                  </Grid>
                </Grid>
                  )}
                </Draggable>

              ))
              :
              <></>}
            {provided.placeholder}
          </Grid>

        )}

      </Droppable>

    </DragDropContext>
    </>
  )
}

export default SeeList