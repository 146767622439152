import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { getIconComponentByTypeWithProps } from "utils/assets";
import { Tooltip as MTooltip} from "@mui/material";


import CircularProgress from "@mui/material/CircularProgress";
const valueFixed = (value) => {
  if (!value.toFixed) {
    return value;
  }

  return value.toFixed(2);
};
const getIntroOfPage = (data, type) => {
  if (type === "distance") {
    return (
      <p className="label" style={{ color: "#A4A114" }}>
        <b>Distance:</b> {valueFixed(data)} m
      </p>
    );
  } else if (
    type === "current_flight" &&
    data[0].dataKey === "current_flight_elevation"
  ) {
    return (
      <p className="label" style={{ color: data[0].color }}>
        <b>Current Flight:</b> {valueFixed(data[0].value)}
      </p>
    );
  } else if (
    type === "previous_flight" &&
    data[1]?.dataKey === "previous_flight_elevation"
  ) {
    return (
      <p className="label" style={{ color: data[1].color }}>
        <b>Previous Flight:</b> {valueFixed(data[1].value)}
      </p>
    );
  } else if (type === "design_dem" && data[1]?.dataKey === "flight_design") {
    return (
      <p className="label" style={{ color: data[1].color }}>
        <b>Design DEM:</b> {valueFixed(data[1].value)}
      </p>
    );
  }
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 8px 10px 1px rgba(0,0,0,0.14)",
          borderRadius: "4px",
          padding: "6px 10px",
        }}
      >
        {getIntroOfPage(label, "distance")}
        {getIntroOfPage(payload, "current_flight")}
        {getIntroOfPage(payload, "previous_flight")}
        {getIntroOfPage(payload, "design_dem")}
      </div>
    );
  }

  return null;
};

const ProfileGraph = ({closeProfile}) => {
  const chartData = useSelector((state) => state.measurementProfiler.chartData);
  const latLon = useSelector((state) => state.measurementProfiler.latLon);
  const list = useSelector((state) => state.measurementList.data);
  const geoJsonList = useSelector(
    (state) => state.geoJsonLayers.measurementData
  );
  const [marker, setMarker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const markerRef = useRef(marker);
  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [chartData]);
  useEffect(() => {
    return () => {
      markerRef?.current?.remove?.();
    };
  }, []);

  const theme = useTheme();
  const getLatLon = (e) => {
    const xAxisVal = e.activeLabel;

    if (!_.isUndefined(xAxisVal)) {
      const index = chartData?.findIndex(
        (item) => valueFixed(item.name) === xAxisVal
      );
      const coordinates = index && latLon[index];

      if (!_.isNull(marker) && coordinates) {
        markerRef.current = marker;
        marker.setLngLat(coordinates);
      } else if (coordinates) {
        setMarker(
          new mapboxgl.Marker({
            color: theme.palette.primary.main,
          })
            .setLngLat(coordinates)
            .addTo(window.map)
        );
      }
    }
  };

  return (
    <>
    {  chartData ? 
    (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: 10, paddingTop: 10, fontSize: 18 }}>
        <b>{list.find((l) => l?.id === geoJsonList?.id)?.name}</b>
        <MTooltip title="Close Profile"  placement="left">
        <IconButton
            onClick={closeProfile}
          >
            {getIconComponentByTypeWithProps('close')({style: {color: 'black'}})}
          </IconButton>
          </MTooltip>
      </div>
      {isLoading ? ( // Show loader when isLoading is true
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            width="100%"
            height={350}
            data={chartData?.map((cd) => {
              return {
                ...cd,
                name: valueFixed(cd.name),
              };
            })}
            margin={{
              top: 5,
              right: 10,
              left: 30,
              bottom: 50,
            }}
            // activeLabel
            onMouseMove={getLatLon}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tickFormatter={chartData}>
              <Label
                value="Distance b/w points (m)"
                offset={-20}
                position="insideBottom"
              />
            </XAxis>
            <YAxis domain={["auto", "auto"]}>
              <Label
                value="Elevation (m)"
                angle={-90}
                offset={-20}
                position="insideLeft"
                textAnchor="middle"
              />
            </YAxis>
            {/*<Tooltip/>*/}
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="top" height={36} />
            <Line
              type="monotone"
              dataKey="current_flight_elevation"
              stroke="#8884d8"
              dot={false}
              strokeWidth={2}
              name="Current flight elevation"
            />
            <Line
              type="monotone"
              dataKey="previous_flight_elevation"
              stroke="#82ca9d"
              dot={false}
              strokeWidth={2}
              name="Previous flight elevation"
            />
            <Line
              type="monotone"
              dataKey="flight_design"
              stroke="#FF3333"
              dot={false}
              strokeWidth={2}
              name="Design elevation"
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
    ) :''
}
    </>
  );
};

export default ProfileGraph;
