import { call, put } from "redux-saga/effects";
import { signupRequest } from "../../requests/basic/signupRequest";
import {
  setSignupServerErrorsAction,
  setSignupServerSuccessAction,
} from "../../../slices/basic/signupSlice";

export function* signupHandler(action) {
  try {
    const response = yield call(signupRequest, { ...action.payload });
    localStorage.setItem("token", response.data.key);
    localStorage.setItem("isAuthenticated", "true");
    yield put(setSignupServerSuccessAction());
    window.location.reload();
  } catch (error) {
    if (error.response.status !== 500)
      yield put(setSignupServerErrorsAction({ errors: error.response.data }));
    console.log({ ...error });
  }
}
