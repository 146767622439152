import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import {useSelector} from "react-redux";

const SmartTrackingPolygonDetail = ({item}) => {

  const projectMaterialName = useSelector(state => state.projectMaterialList.data.filter(projectMaterial => projectMaterial.id === item.project_material)?.[0]?.name)

  return (
    <Grid container direction="column">
      <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Typography>Name</Typography>
          <Typography sx={{color: item.color}}>{item.name}</Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Typography>Material Type</Typography>
          <Typography>{projectMaterialName}</Typography>
        </Box>
      </Grid>
      {item.area && <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Typography>Area</Typography>
          <Typography>{item.area.toFixed(2)}</Typography>
        </Box>
      </Grid>}
      {item.stats && item.stats.net_loss > -1 && <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Typography>Cut</Typography>
          <Typography>{item.stats.net_loss.toFixed(2)}</Typography>
        </Box>
      </Grid>}
      {item.stats && item.stats.net_gain > -1 && <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Typography>Fill</Typography>
          <Typography>{item.stats.net_gain.toFixed(2)}</Typography>
        </Box>
      </Grid>}
      {item?.stats && item?.stats?.net_volume !== 0 && <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Typography>Net</Typography>
          <Typography>{item?.stats?.net_volume?.toFixed(2)}</Typography>
        </Box>
      </Grid>}

    </Grid>
  )
}

export default SmartTrackingPolygonDetail