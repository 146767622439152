import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { Tooltip } from "@mui/material";
import { checkCutandFill, checkUnit } from "utils/layers/mapUtils";

const PolygonDetail = ({ item }) => {
  const stockpileVolume = Math.abs(
    parseFloat(item.properties.stockpile_volume_TIN_METHOD).toFixed(2)
  );

  const stockpileVolumeLPB = Math.abs(
    parseFloat(item.properties.stockpile_volume_MIN_METHOD).toFixed(2)
  );
  const progressVolume = Math.abs(
    parseFloat(item.properties.progress_volume).toFixed(2)
  );

const formattedString = `${item?.properties?.area.toFixed(2)} ${item?.properties.unit}<sup>${checkUnit('area')}</sup>`;

  return (
    <Grid container direction="column">
      <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Typography className="lblStyle">Name</Typography>
          <Typography sx={{ color: item?.color }} className="lblStyle">
            {item?.name}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Typography className="lblStyle">Label</Typography>
          <Typography className="lblStyle">{item?.label}</Typography>
        </Box>
      </Grid>
      {/* {item.properties.area && ( */}
      <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Typography className="lblStyle">Area</Typography>
          <Typography className="lblStyle" dangerouslySetInnerHTML={{ __html: typeof item?.properties?.area === 'number' ? formattedString : 'N/A' }} />
        </Box>
      </Grid>
      {/* )} */}
      {/* {item.properties.perimeter && ( */}
      <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Typography className="lblStyle">Perimeter</Typography>
          <Typography className="lblStyle">
            {typeof item?.properties?.perimeter === 'number'
              ? `${item?.properties?.perimeter?.toFixed(2)} ${item?.properties.unit} ${checkUnit(item?.properties?.perimeter)}`
              : "N/A"}
          </Typography>
        </Box>
      </Grid>
      {/* )} */}
      {/* {item.properties.progress_volume && ( */}

      <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Typography className="lblStyle">Progress Vol.</Typography>
          <Typography className="lblStyle">
          {typeof item?.properties?.progress_volume === "number" ? 
              (checkCutandFill(item?.properties?.progress_volume, item?.properties.unit,'progress_volume')) : '-'
            }
          </Typography>
        </Box>
      </Grid>
      {/* )} */}
      {/* {item.properties.stockpile_volume_TIN_METHOD && ( */}

      <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Tooltip title="Stockpile Vol. (Tin Base)">
            <Typography className="lblStyle">Stockpile Vol. (TB)</Typography>
          </Tooltip>

          <Typography className="lblStyle">
            {typeof item?.properties?.stockpile_volume_TIN_METHOD === "number" ? (
              <>
                <span>
              {typeof item?.properties?.stockpile_volume_TIN_METHOD === 'number' ? ( checkCutandFill(item?.properties?.stockpile_volume_TIN_METHOD, item?.properties?.unit, 'stockpile_volume_TIN_METHOD'))  : '-'}

                </span>
                {/* &nbsp; */}
                {/* <span>
                  (
                  {item.properties.stockpile_volume_TIN_METHOD < 0
                    ? "Cut"
                    : "Fill"}
                  )
                </span> */}
              </>
            ) : (
              <span>-</span>
            )}
          </Typography>
        </Box>
      </Grid>
      {/* )} */}

      <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          {/* <Tooltip title="Stockpile Vol. (Low Point Base)"> */}
            <Typography className="lblStyle">Stockpile Vol. (LPB)</Typography>
          {/* </Tooltip> */}
          <Typography className="lblStyle">
            {typeof item?.properties?.stockpile_volume_MIN_METHOD === "number" ? (
              <>
                <span>
                  {/* {item?.properties?.stockpile_volume_MIN_METHOD.toFixed(2)} m³ */}
              {typeof item?.properties?.stockpile_volume_MIN_METHOD === 'number' ? (checkCutandFill(item?.properties?.stockpile_volume_MIN_METHOD, item?.properties.unit,'stockpile_volume_MIN_METHOD')) : '-'}

                </span>
                {/* &nbsp; */}
                {/* <span>
                  (
                  {item.properties.stockpile_volume_MIN_METHOD < 0
                    ? "Cut"
                    : "Fill"}
                  )
                </span> */}
              </>
            ) : (
              <span>-</span>
            )}

            {/* {item.properties.stockpile_volume_MIN_METHOD < 0 ? (
              <>
                <span>{stockpileVolumeLPB} m³</span>&nbsp;
                <span>(Cut)</span>
              </>
            ) : (
              <>
                <span>{stockpileVolumeLPB} m³</span>&nbsp;
                <span>(fill)</span>
              </>
            )} */}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          {/* <Tooltip title="Stockpile Vol. (Low Point Base)"> */}
            <Typography className="lblStyle">Design vol.</Typography>
          {/* </Tooltip> */}
          <Typography className="lblStyle">
          {item?.properties?.design_volume && typeof item?.properties?.design_volume === 'number' ? checkCutandFill(item?.properties?.design_volume, item?.properties?.unit,'design_volume'): '-'}

          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PolygonDetail;
