import React, { useState } from 'react';
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import { ListItemText, styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import StraightenIcon from "@mui/icons-material/Straighten";
import TerrainIcon from "@mui/icons-material/Terrain";
import TransformIcon from "@mui/icons-material/Transform";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import GetAppIcon from "@mui/icons-material/GetApp";
import QuizIcon from '@mui/icons-material/Quiz';

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useDispatch, useSelector } from "react-redux";
import { updateDrawerItemSelectedAction } from "../../../redux/slices/dashboard/drawerSlice";
import { NavLink, useHistory } from "react-router-dom";
import TicketModel from "pages/Support/TicketModel";

import { Tooltip } from "@mui/material";
import _ from "lodash";

const IconPaperStyled = styled(Paper)(({ theme, selected }) => ({
  width: 34.06,
  height: 32,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

const iconMap = {
  FolderSpecialIcon: <FolderSpecialIcon />,
  FlightTakeoffIcon: <FlightTakeoffIcon />,
  ViewQuiltIcon: <ViewQuiltIcon />,
  VisibilityIcon: <VisibilityIcon />,
  StraightenIcon: <StraightenIcon />,
  TerrainIcon: <TerrainIcon />,
  TransformIcon: <TransformIcon />,
  CreateNewFolderIcon: <CreateNewFolderIcon />,
  GetAppIcon: <GetAppIcon />,
  AccountCircleIcon: <AccountCircleIcon />,
  QuizIcon: <QuizIcon />
};

const MenuItems = () => {
  const menuItems = useSelector((state) => state.drawerState.menuItems);
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedProjectId = useSelector((state) => state.selectedProject.id);
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);

  const openSupportModal = () => {
    setIsSupportModalOpen(true);
  };
  return (
    <>
 <TicketModel isSupportModalOpen={isSupportModalOpen} handleCloseSupportModal={() => setIsSupportModalOpen(false)} />
    
    <List>
   {menuItems.map((item, index) => {
  if (item?.name === 'Account') {
    return null; // or return undefined
  } else {
    return (
      <Paper
        key={index}
        elevation={item.selected ? 4 : 0}
        style={{ marginBottom: 10 }}
      >
        <Tooltip title={item?.name}>
          <ListItemButton
            selected={item.selected}
            component={NavLink}
            to={item?.name === 'Support' ? '/project' : item.link}
            onClick={(e) => {
              if(item?.name === 'Support') {
                setIsSupportModalOpen(true);
                // return;
              }
               
              else
                dispatch(updateDrawerItemSelectedAction({ index: index }))

            }
            }
            disabled={item?.name !== 'Projects' && item?.name !== 'Support'  }
            // disabled={item.link !== "/project" && _.isNil(selectedProjectId)}
          
          >
            <ListItemIcon>
              <IconPaperStyled
                elevation={item.selected ? 1 : 2}
                selected={item.selected}
              >
                {iconMap.hasOwnProperty(item.icon) && iconMap[item.icon]}
              </IconPaperStyled>
            </ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItemButton>
        </Tooltip>
      </Paper>
    );
  }
})}

    </List>
  
  </>
  );
};
export default MenuItems;
