import { useState, useEffect } from "react";
import Button from "../Common/Button";
import { setProjectListMapViewAction } from "../../redux/slices/project/list";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutAction } from "../../redux/slices/basic/LogoutSlice";
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
  styled,
  Box,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Loader from "../Common/Loader";

const MainWraper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
}));
const AccountSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "10px 10px",
  borderBottom: "1px solid #ccc",
}));

const ProjectAppBarContent = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const mapView = useSelector((state) => state.projectList.mapView);
  const name = localStorage.getItem("name");
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const userEmail = localStorage.getItem("userEmail");
    const userAuthenticated = localStorage.getItem("isAuthenticated");
    if (userEmail && userAuthenticated) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLogoutAndNavigation = () => {
    setLoading(true);

    setTimeout(() => {
      dispatch(logoutAction());
      localStorage.removeItem("token");
      localStorage.removeItem("isAuthenticated");
      history.push("/");
    }, 3000);
  };
  const handleMapViewClick = () => {
    dispatch(setProjectListMapViewAction({ mapView: !mapView }));
  };

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <MainWraper>
        {isLoading ? <Loader /> : null}
        <Box>
          <Button
            label={!mapView ? "Map View" : "List View"}
            iconPosition="startIcon"
            iconType="location"
            sx={{
              minWidth: 120,
              height: 40,
            }}
            onClick={handleMapViewClick}
          />
        </Box>

        <div style={{ marginLeft: 20 }}>
          {/* <Typography color="white">Welcome: {name}</Typography> */}
          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <AccountCircle
                sx={{ width: 40, height: 40 }}
                style={{ color: "gray" }}
              />
            </IconButton>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              PaperProps={{
                style: {
                  width: 220,
                },
              }}
            >
              <AccountSection>
                <Typography
                  style={{
                    marginLeft: 10,
                    fontWeight: 700,
                    fontSize: 12,
                    color: "#5E6C84",
                    marginBottom: 10,
                  }}
                >
                  Account
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AccountCircle
                    sx={{ width: 40, height: 40 }}
                    style={{ color: "gray" }}
                  />
                  <Typography style={{ marginLeft: 10, fontWeight: 700 }}>
                    {name}
                  </Typography>
                </div>
              </AccountSection>
              <MenuItem
                onClick={handleLogoutAndNavigation}
                style={{
                  padding: "15px 10px",
                }}
              >
                <Typography textAlign="center">Sign out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </div>
      </MainWraper>
    </>
  );
};

export default ProjectAppBarContent;
