import { call, put, select } from "redux-saga/effects";
import {
  deleteProjectRequest,
  editProjectRequest,
  getProjectListRequest,
  projectCreateRequest,
} from "../requests/projectRequests";
import {
  setProjectCreateOpenPopupAction,
  setProjectCreateOrEditLoadingAction,
  setProjectEditSelectedIdAction,
} from "../../slices/project/createOrEdit";
import {
  getProjectListAction,
  setProjectListDataAction,
  setProjectListLoadingAction,
} from "../../slices/project/list";
import {
  removeSelectedProjectAction,
  selectProjectAction,
  setSelectedProjectAction,
} from "../../slices/project/selected";
import {
  setDeleteProjectLoadingAction,
  setDeleteProjectSelectedIdAction,
} from "../../slices/project/delete";
import { getFlightListAction } from "../../slices/flight/list";
import isNull from "lodash/isNull";
import markerImg from "../../../images/location-marker.png";
import mapboxgl from "mapbox-gl";
import { onProjectMarkerClick } from "../helpers/projectHelper";
import { resetMeasurementFiltersAction } from "../../slices/measurement/list";

export function* projectCreateHandler(action) {
  try {
    const history = action.payload.history;
    delete action.payload.history;
    const flightId = yield select((state) => state.flightSelected.id);

    // Make the project creation request
    const response = yield call(projectCreateRequest, action.payload);

    // Set loading and close popup
    yield put(setProjectCreateOrEditLoadingAction({ loading: false }));
    yield put(setProjectCreateOpenPopupAction({ openPopup: false }));

    // Select the project and update project list
    yield put(
      selectProjectAction({ project: response.data, history: history })
    );
    yield put(getProjectListAction());

    // Default latitude and longitude for the map
    const defaultLatitude = 53.69;
    const defaultLongitude = -106.81;

    // Remove markers from the map if flightId is zero, null, or undefined
    if (!flightId) {
      // Access the map object from the window
      const map = window.map;

      // Check if the map object exists and has the _markers property
      if (map && map._markers) {
        // Iterate through all the markers and remove them from the map
        Object.values(map._markers).forEach((marker) => {
          marker.remove();
        });

        // Fly the map to the default latitude and longitude
        map.flyTo({
          center: [defaultLongitude, defaultLatitude],
          zoom: 2,
        });
      }
    }

    // ... Other actions or logic if needed
  } catch (error) {
    console.log(error);
  }
}
export function* getProjectListHandler() {
  try {
    const response = yield call(getProjectListRequest);
    yield put(setProjectListLoadingAction({ loading: false }));
    yield put(setProjectListDataAction({ data: response.data }));
  } catch (error) {
    console.log(error);
  }
}

export function* deleteProjectHandler(action) {
  try {
    yield call(deleteProjectRequest, action.payload.id);
    yield put(getProjectListAction());
    yield put(setDeleteProjectLoadingAction());
    yield put(setDeleteProjectSelectedIdAction({ id: null }));
    const selectedProjectId = yield select((state) => state.selectedProject.id);
    if (selectedProjectId === action.payload.id) {
      yield put(removeSelectedProjectAction());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* editProjectHandler(action) {
  try {
    yield call(editProjectRequest, action.payload.id, action.payload.payload);
    yield put(setProjectCreateOrEditLoadingAction({ loading: false }));
    yield put(setProjectEditSelectedIdAction({ id: null }));
    yield put(getProjectListAction());
  } catch (error) {
    console.log(error);
  }
}

export function* selectedProjectHandler(action) {
  try {
    yield put(setSelectedProjectAction({ project: action.payload.project }));
    yield put(resetMeasurementFiltersAction());
    if(action.payload.project?.location?.coordinates) {
      window.map &&
      window.map.flyTo({
        center: action.payload.project.location.coordinates,
        zoom: 15,
        speed: 2,
      });
    }

    yield put(getFlightListAction());
    window.map._markers.forEach((marker) => marker.remove());
    action.payload.history.push("/flight");
  } catch (error) {
    console.log(error);
  }
}

export function* createProjectMarkerHandler(action) {
  const history = action.payload.history;
  const projects = yield select((state) => state.projectList.data);
  const bounds = new mapboxgl.LngLatBounds([0, 0], [0, 0]);
  // for (const image of imagesData) {
  //   bounds.extend(image.location);
  // }
  if (isNull(window.map) || projects.length === 0) return;
  projects.forEach((project) => {
    if (!isNull(project.location)) {
      const el = document.createElement("div");
      el.className = "marker";
      el.style.backgroundImage = `url(${markerImg})`;
      el.style.width = `${50}px`;
      el.style.height = `${50}px`;
      el.style.backgroundSize = "100%";

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 25,
      });

      el.addEventListener("mouseenter", () => {
        popup
          .setHTML(
            `<div><div>${project.name}</div><br><div>${project.description}</div> </div>`
          )
          .addTo(window.map);
      });

      el.addEventListener("mouseleave", () => {
        popup.remove();
      });

      const marker = new mapboxgl.Marker(el)
        .setLngLat(project.location.coordinates)
        .setPopup(popup)
        .addTo(window.map);
      bounds.extend(project.location.coordinates);

      el.addEventListener("click", () => {
        onProjectMarkerClick(project, marker, history);
      });
    }
    window.map && window.map.fitBounds(bounds, { padding: 300, maxZoom: 22 });
  });
}
