import { takeEvery, takeLeading } from "redux-saga/effects";
import { googleLoginAction, loginAction } from "../slices/basic/loginSlice";
import {
  // logoutSuccess,
  // logoutFailure,
  logoutAction,
} from "../slices/basic/LogoutSlice";
import {
  googleLoginHandler,
  loginHandler,
  logoutHandler,
} from "./handlers/basic/loginHandler";
import {
  createProjectMarkerHandler,
  deleteProjectHandler,
  editProjectHandler,
  getProjectListHandler,
  projectCreateHandler,
  selectedProjectHandler,
} from "./handlers/projectHandlers";
import {
  createProjectAction,
  editProjectAction,
} from "../slices/project/createOrEdit";
import {
  createProjectMarkerAction,
  getProjectListAction,
} from "../slices/project/list";
import { deleteProjectAction } from "../slices/project/delete";
import { selectProjectAction } from "../slices/project/selected";
import {
  createFlightWebSocketAction,
  getFlightListAction,
} from "../slices/flight/list";
import {
  checkRawFlightToRemoveHandler,
  createFlightWebSocketHandler,
  createGeoTiffFlightHandler,
  createRawFlightHandler,
  deleteFlightHandler,
  downloadSampleGCPFileHandler,
  editFlightNameHandler,
  getFlightListHandler,
  handleGcpFileDropHandler,
  rawFilesDropHandler,
  removeSelectedFlightHandler,
  selectedFlightHandler,
} from "./handlers/flightHandlers";
import {
  checkRawFlightToRemoveAction,
  createGeoTiffFlightAction,
  createRawFlightAction,
  downloadSampleGCPFileAction,
  gcpFileDropAction,
  rawFilesDropAction,
} from "../slices/flight/create";
import {
  createRawFlightGCPLayerAction,
  createRawFlightImageLayerAction,
  removeRawFlightLayersAction,
} from "../slices/layers/flightUploadLayers";
import {
  createGcpGeoJsonLayerHandler,
  createRawFlightImageLayerHandler,
  removeRawFlightLayersHandler,
} from "./handlers/layers/flightUploadLayersHandler";
import { editFlightNameAction } from "../slices/flight/edit";
import { deleteFlightAction } from "../slices/flight/delete";
import { getRasterLayersAction } from "../slices/see/list";
import { seeListHandler } from "./handlers/seeHandler";
import {
  removeSelectedFlightAction,
  selectFlightAction,
} from "../slices/flight/selected";
import {
  createRasterLayerAction,
  rearrangeRasterLayersAction,
} from "../slices/layers/rasterLayersSlice";
import {
  createRasterLayerHandler,
  rearrangeRasterLayersHandler,
} from "./handlers/layers/rasterLayersHandler";
import { getCommonMaterialAction } from "../slices/material/material";
import {
  deleteProjectMaterialHandler,
  editProjectMaterialHandler,
  getCommonMaterialHandler,
  getProjectMaterialListHandler,
  projectMaterialCreateHandler,
} from "./handlers/materialHandler";
import { createProjectMaterialAction } from "../slices/material/projectMaterial/create";
import { getProjectMaterialListAction } from "../slices/material/projectMaterial/list";
import { editProjectMaterialAction } from "../slices/material/projectMaterial/edit";
import { deleteProjectMaterialAction } from "../slices/material/projectMaterial/delete";
import {
  saveDSMExportAction,
  saveMeasurementExportAction,
  saveOrthoExportAction,
  saveTerrainExportAction,
  saveExportSmartTrackingAction,
  saveExportExcelAction
} from "../slices/export";
import {
  saveDSMExportHandler,
  saveMeasurementExportHandler,
  saveOrthoExportHandler,
  saveTerrainExportHandler,
  saveExportSmartTrackingHandler,
  saveExportExcelHandler
} from "./handlers/exportHandler";
import { createDesignAction } from "../slices/design/create";
import {
  createDesignHandler,
  deleteDesignFileHandler,
  getDesignListHandler,
  saveDesignFileHandler,
} from "./handlers/designHandler";
import { getDesignListAction } from "../slices/design/list";
import {
  deleteDesignFileAction,
  saveDesignFileAction,
} from "../slices/design/deleteAndSave";
import { createMeasurementAction } from "../slices/measurement/create";
import {
  createMeasurementHandler,
  createMeasurementProfileHandler,
  createSocketConnectionHandler,
  downloadMeasurementGeoJsonHandler,
  editMeasurementHandler,
  formatMeasurementProfileDataHandler,
  getMeasurementListHandler,
  handleMeasurementListDataHandler,
  measurementDeleteHandler,
  updateMeasureDataListHandler,
} from "./handlers/measurements";
import {
  createSocketConnectionAction,
  downloadMeasurementGeoJsonAction,
  getMeasurementListAction,
  handleMeasurementListDataAction,
  updateMeasureDataListAction,
} from "../slices/measurement/list";
import {
  createMeasurementLayerAction,
  createSmartTrackingPolygonLayers,
  measurementItemSelectAction,
} from "../slices/layers/geoJsonLayersSlice";
import {
  createMeasurementLayerHandler,
  createSmartTrackingPolygonLayerHandler,
  measurementItemSelectHandler,
} from "./handlers/layers/geoJsonLayerHandler";

// profile
import { measureProfileSelectAction } from "../slices/layers/geoJsonLayersSlice";
import { measureProfileHandler, removeMeasureProfileHandler } from "./handlers/layers/geoJsonLayerHandler";

import { editMeasurementAction } from "../slices/measurement/edit";
import { measurementDeleteAction } from "../slices/measurement/delete";
import {
  hideAllLayersAction,
  rearrangeLayersAfterRefreshAction,
  retainMapStateAfterRefreshAction,
} from "../slices/layers";
import {
  hideAllLayersHandler,
  rearrangeLayersAfterRefreshHandler,
  retainMapStateAfterRefreshHandler,
} from "./handlers/layers";
import { createSmartTrackingPolygonAction } from "../slices/smartTracking/create";
import {
  createSmartTrackingPolygonHandler,
  deleteSmartTrackingPolygonHandler,
  editSmartTrackingPolygonHandler,
  getSmartTrackingPolygonListHandler,
} from "./handlers/smartTrackingHandler";
import { getSmartTrackingPolygonListAction } from "../slices/smartTracking/list";
import { deleteSmartTrackingPolygonAction } from "../slices/smartTracking/delete";
import { editSmartTrackingPolygonAction } from "../slices/smartTracking/edit";
import { signupAction } from "../slices/basic/signupSlice";
import { signupHandler } from "./handlers/basic/signupHandler";
import {
  resetPasswordAction,
  resetPasswordConfirmAction,
  setAuthorizeLinkRequest,
} from "../slices/basic/resetPasswordSlice";
import {
  authorizeRequestHandler,
  resetPasswordConfirmHandler,
  resetPasswordHandler,
} from "./handlers/basic/resetPasswordHandler";
import {
  createMeasurementProfileAction,
  formatMeasurementProfileDataAction,
  removeMeasureProfileSelectAction
} from "../slices/measurement/profiler";
import { stockpileReportHandler } from 'redux/sagas/handlers/reportHandler';
import { stockpileReportAction } from "../slices/report";

export function* watcherSaga() {
  // Basic
  yield takeLeading(signupAction.type, signupHandler);
  yield takeLeading(loginAction.type, loginHandler);
  yield takeLeading(resetPasswordAction.type, resetPasswordHandler);
  yield takeLeading(
    resetPasswordConfirmAction.type,
    resetPasswordConfirmHandler
  );
  yield takeLeading(setAuthorizeLinkRequest.type, authorizeRequestHandler);

  yield takeLeading(logoutAction.type, logoutHandler);
  yield takeEvery(googleLoginAction.type, googleLoginHandler);

  //  Projects
  yield takeEvery(createProjectAction.type, projectCreateHandler);
  yield takeEvery(getProjectListAction.type, getProjectListHandler);
  yield takeEvery(deleteProjectAction.type, deleteProjectHandler);
  yield takeEvery(editProjectAction.type, editProjectHandler);
  yield takeEvery(selectProjectAction.type, selectedProjectHandler);
  yield takeEvery(createProjectMarkerAction.type, createProjectMarkerHandler);

  // Flights
  yield takeEvery(getFlightListAction.type, getFlightListHandler);
  yield takeEvery(createGeoTiffFlightAction.type, createGeoTiffFlightHandler);
  yield takeEvery(createRawFlightAction.type, createRawFlightHandler);
  yield takeEvery(rawFilesDropAction.type, rawFilesDropHandler);
  yield takeLeading(
    downloadSampleGCPFileAction.type,
    downloadSampleGCPFileHandler
  );
  yield takeLeading(gcpFileDropAction.type, handleGcpFileDropHandler);
  yield takeLeading(editFlightNameAction.type, editFlightNameHandler);
  yield takeEvery(removeSelectedFlightAction.type, removeSelectedFlightHandler);
  yield takeEvery(selectFlightAction.type, selectedFlightHandler);
  yield takeLeading(deleteFlightAction.type, deleteFlightHandler);
  yield takeEvery(
    createFlightWebSocketAction.type,
    createFlightWebSocketHandler
  );
  yield takeLeading(
    checkRawFlightToRemoveAction.type,
    checkRawFlightToRemoveHandler
  );

  // Layers
  yield takeEvery(
    createRawFlightImageLayerAction.type,
    createRawFlightImageLayerHandler
  );
  yield takeEvery(
    createRawFlightGCPLayerAction.type,
    createGcpGeoJsonLayerHandler
  );
  yield takeEvery(
    removeRawFlightLayersAction.type,
    removeRawFlightLayersHandler
  );
  yield takeEvery(createRasterLayerAction.type, createRasterLayerHandler);
  yield takeEvery(
    rearrangeRasterLayersAction.type,
    rearrangeRasterLayersHandler
  );
  yield takeEvery(
    createMeasurementLayerAction.type,
    createMeasurementLayerHandler
  );
  yield takeEvery(hideAllLayersAction.type, hideAllLayersHandler);
  yield takeEvery(
    measurementItemSelectAction.type,
    measurementItemSelectHandler
  );

  yield takeEvery(measureProfileSelectAction.type, measureProfileHandler);
  yield takeEvery(removeMeasureProfileSelectAction.type, removeMeasureProfileHandler);
  


  yield takeEvery(
    createSmartTrackingPolygonLayers.type,
    createSmartTrackingPolygonLayerHandler
  );
  yield takeEvery(
    retainMapStateAfterRefreshAction.type,
    retainMapStateAfterRefreshHandler
  );
  yield takeEvery(
    rearrangeLayersAfterRefreshAction.type,
    rearrangeLayersAfterRefreshHandler
  );

  // See
  yield takeEvery(getRasterLayersAction.type, seeListHandler);

  // Material
  yield takeLeading(getCommonMaterialAction.type, getCommonMaterialHandler);
  yield takeEvery(
    createProjectMaterialAction.type,
    projectMaterialCreateHandler
  );
  yield takeEvery(
    getProjectMaterialListAction.type,
    getProjectMaterialListHandler
  );
  yield takeEvery(editProjectMaterialAction.type, editProjectMaterialHandler);
  yield takeEvery(
    deleteProjectMaterialAction.type,
    deleteProjectMaterialHandler
  );

  // Export
  yield takeLeading(saveOrthoExportAction.type, saveOrthoExportHandler);
  yield takeLeading(saveTerrainExportAction.type, saveTerrainExportHandler);
  yield takeLeading(saveDSMExportAction.type, saveDSMExportHandler);
  yield takeLeading(
    saveMeasurementExportAction.type,
    saveMeasurementExportHandler
  );
  yield takeLeading(saveExportSmartTrackingAction.type, saveExportSmartTrackingHandler);
  yield takeLeading(saveExportExcelAction.type, saveExportExcelHandler);


  // Design
  yield takeLeading(createDesignAction.type, createDesignHandler);
  yield takeLeading(getDesignListAction.type, getDesignListHandler);
  yield takeLeading(saveDesignFileAction.type, saveDesignFileHandler);
  yield takeLeading(deleteDesignFileAction.type, deleteDesignFileHandler);

  // Measurement
  yield takeEvery(createMeasurementAction.type, createMeasurementHandler);
  yield takeEvery(getMeasurementListAction.type, getMeasurementListHandler);
  yield takeEvery(
    handleMeasurementListDataAction.type,
    handleMeasurementListDataHandler
  );
  yield takeLeading(
    createSocketConnectionAction.type,
    createSocketConnectionHandler
  );
  yield takeEvery(
    updateMeasureDataListAction.type,
    updateMeasureDataListHandler
  );
  yield takeEvery(
    downloadMeasurementGeoJsonAction.type,
    downloadMeasurementGeoJsonHandler
  );
  yield takeEvery(editMeasurementAction.type, editMeasurementHandler);
  yield takeEvery(measurementDeleteAction.type, measurementDeleteHandler);
  yield takeEvery(
    createMeasurementProfileAction.type,
    createMeasurementProfileHandler
  );
  yield takeEvery(
    formatMeasurementProfileDataAction.type,
    formatMeasurementProfileDataHandler
  );

  // Smart Tracking
  yield takeEvery(
    createSmartTrackingPolygonAction.type,
    createSmartTrackingPolygonHandler
  );
  yield takeEvery(
    getSmartTrackingPolygonListAction.type,
    getSmartTrackingPolygonListHandler
  );
  yield takeEvery(
    deleteSmartTrackingPolygonAction.type,
    deleteSmartTrackingPolygonHandler
  );
  yield takeEvery(
    editSmartTrackingPolygonAction.type,
    editSmartTrackingPolygonHandler
  );

  //report
  yield takeLeading(stockpileReportAction.type, stockpileReportHandler);
  
}
