import PrivateAPI from "../../../../utils/API/PrivateAPI";
import {END, eventChannel} from "redux-saga";

export const PrivateRequestExcel = (method, url) => {
    return PrivateAPI.request({
          method,
          url,
          responseType: 'blob'
      })
  }
  
const PrivateRequestBase = (method, url, data, options={}) => {
  return PrivateAPI.request({
        method,
        url,
        data,
        ...options
    })
}

export default PrivateRequestBase

export function RequestEmitter(requestBase, payload) {
        return eventChannel(emitter => {
            const requestConfig = {
            onUploadProgress: function (progressEvent) {
                const {loaded, total} = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                emitter({percent})
            }
        }
        requestBase(payload, requestConfig)
            .then(response => {emitter({response}); emitter(END)})
            .catch(error => {emitter({error}); emitter(END)})
        return () => {}
        })
}